// import AuthContext from "AppContext/AuthProvider";
import { useContext, FC, useState } from "react";
import router from "next/router";
import ConfirmationDialog from "../components/ConfirmationDialog";
import Typo from "../components/Typo";
import { ROUTES } from "../Constants/variables";
import ContentModalContext from "../AppContext/ContentModalProvider";
import AuthModel from "../models/auth";

export interface TError {
  code: string;
  metaData?: string;
  message?: string;
  extraFns?: () => void;
}
export interface IErrorHandler {
  handleError: (error: TError) => void;
}
const goToLoginPage = async () => {
  router.push(ROUTES.LOGIN);
};

const goToSignUpPage = async () => {
  router.push(ROUTES.SIGNUP);
};

const goToContactPage = async () => {
  router.push(ROUTES.CONTACT_US);
};

// const goToDashboard = async () => {
//   router.push(ROUTES.DASHBOARD);
// };

const ResendEmailVerificationLink: FC<TError> = ({ metaData }) => {
  const { hideModal } = useContext(ContentModalContext);
  const [message, setMessage] = useState<string>();
  return (
    <ConfirmationDialog
      title="Email not verified"
      body="Looks like this email is not verified. Click send to receive a new verification link in your email."
      open
      message={message}
      actions={[
        {
          children: "Cancel",
          onClick: hideModal,
        },
        {
          children: "Send",
          onClick: async () => {
            if (metaData) {
              await AuthModel.resendEmailVerifyLink(metaData);
              setMessage("Great! Email has been sent.");
            }
          },
          theme: "Filled - Lime Green Gradient",
        },
      ]}
      onClose={hideModal}
    />
  );
};
/**
 * Email verification link expired -DONE
 * Email not verified -DONE
 * Reset password link expired
 * Invitation link expired -DONE
 * Invalid invitation -DONE
 *
 * @returns
 */
const useErrorHandlers = (): IErrorHandler => {
  const { showModal, hideModal } = useContext(ContentModalContext);
  // const { appUser } = useContext(AuthContext);

  const handleError = (error: TError) => {
    switch (error.code) {
      case "EMAIL_VERIFICATION_TOKEN_EXPIRED":
        showModal(
          <ConfirmationDialog
            title="This link has expired"
            body="Looks like this link has expired. Click Resend to receive a new link in your email."
            open
            actions={[
              {
                children: "Cancel",
                onClick: hideModal,
              },
              {
                children: "Resend",
                onClick: async () => {
                  error?.metaData &&
                    (await AuthModel.resendEmailVerifyLink(error?.metaData));
                },
                theme: "Filled - Lime Green Gradient",
              },
            ]}
            onClose={hideModal}
          />,
        );
        break;
      case "EMAIL_NOT_VERIFIED":
        showModal(<ResendEmailVerificationLink {...error} />);
        break;
      case "VERIFICATION_MAIL_NOT_SENT_SUCCESSFULLY":
        showModal(<ResendEmailVerificationLink {...error} />);
        break;
      case "ERR_NETWORK":
        showModal(
          <ConfirmationDialog
            title="Something Went Wrong"
            body="We seem to be unable to reach our servers at this time. If your connection is otherwise stable, read over the instructions in our support page by clicking on the Support button below."
            open
            actions={[
              {
                children: "Support",
                onClick: async () => {
                  router.push(
                    "https://support.knopro.org/portal/en/kb/articles/knopro-website-is-not-working",
                  );
                },
                theme: "Filled - Lime Green Gradient",
              },
              {
                children: "Close",
                onClick: hideModal,
              },
            ]}
            onClose={hideModal}
          />,
        );
        break;
      case "INVITATION_TOKEN_EXPIRED":
        showModal(
          <ConfirmationDialog
            title="This link has expired"
            body="Looks like this link has expired."
            open
            actions={[
              {
                children: "Close",
                onClick: hideModal,
              },
            ]}
            onClose={hideModal}
          />,
        );
        break;
      case "INVITATION_NOT_FOUND":
        showModal(
          <ConfirmationDialog
            title="Oops!"
            body="Invitation Not Found."
            open
            actions={[
              {
                children: "Close",
                onClick: hideModal,
              },
            ]}
            onClose={hideModal}
          />,
        );
        break;
      case "RESET_PASSWORD_TOKEN_INVALID":
        showModal(
          <ConfirmationDialog
            title="This link has expired"
            body="Looks like this link has expired. Click Resend to receive a new link in your email."
            open
            actions={[
              {
                children: "Cancel",
                onClick: hideModal,
              },
              {
                children: "Resend",
                onClick: async () => {
                  error?.metaData &&
                    (await AuthModel.forgotPassword({
                      email: error?.metaData,
                    }));
                  goToLoginPage();
                  hideModal();
                },
                theme: "Filled - Lime Green Gradient",
              },
            ]}
            onClose={hideModal}
          />,
        );
        break;
      case "CANNOT_ADD_NEW_MEMBER_WITH_THIS_ROLE":
        showModal(
          <ConfirmationDialog
            title="Sorry! That's too many people..."
            body="Each team can have a maximum of 5 students 
            (including you) and 2 teachers."
            open
            actions={[
              {
                children: "Continue",
                onClick: hideModal,
                theme: "Filled - Lime Green Gradient",
              },
            ]}
            onClose={hideModal}
          />,
        );
        break;
      case "MENTORS_CANNOT_JOIN_PROJECT":
        showModal(
          <ConfirmationDialog
            title="Looks like you're a mentor"
            body="This invitation was meant for teachers and students only. Please contact us if you have any questions."
            open
            actions={[
              {
                children: "Contact Us",
                onClick: async () => {
                  goToContactPage();
                  hideModal();
                },
                theme: "Filled - Lime Green Gradient",
              },
              {
                children: "Got it",
                onClick: hideModal,
              },
            ]}
            onClose={hideModal}
          />,
        );
        break;
      case "TO_MANY_INVITEES":
        showModal(
          <ConfirmationDialog
            title="Sorry! That's too many people..."
            body="Each team can have a maximum of 50 members"
            open
            actions={[
              {
                children: "Continue",
                onClick: hideModal,
              },
            ]}
            onClose={hideModal}
          />,
        );
        break;
      case "INSTANCE_NOT_FOUND":
        showModal(
          <ConfirmationDialog
            title="Oops!"
            body={error.message || "Not Found"}
            open
            actions={[
              {
                theme: "Filled - Lime Green Gradient",
                children: "Got it",
                onClick: hideModal,
              },
            ]}
            hasCloseButton
            onClose={hideModal}
          />,
        );
        break;
      case "ACTION_IN_PROCESS":
        showModal(
          <ConfirmationDialog
            title="Oops!"
            body={error.message || "Not Found"}
            open
            actions={[
              {
                theme: "Filled - Lime Green Gradient",
                children: "Got it",
                onClick: hideModal,
              },
            ]}
            hasCloseButton
            onClose={hideModal}
          />,
        );
        break;
      case "ACTION_ALREADY_PERFORMED":
        showModal(
          <ConfirmationDialog
            title="Oops!"
            body={error.message || "Not Found"}
            open
            actions={[
              {
                theme: "Filled - Lime Green Gradient",
                children: "Got it",
                onClick: hideModal,
              },
            ]}
            hasCloseButton
            onClose={hideModal}
          />,
        );
        break;
      case "USER_VALIDATION_FAILING":
        showModal(
          <ConfirmationDialog
            title="Oops!"
            body="User validation/requirements for database failing"
            open
            actions={[
              {
                theme: "Filled - Lime Green Gradient",
                children: "Try Again",
                onClick: hideModal,
              },
            ]}
            hasCloseButton
            onClose={hideModal}
          />,
        );
        break;
      case "VERIFICATION_TOKEN_NOT_GENERATED":
        showModal(
          <ConfirmationDialog
            title="Oops!"
            body="Verification Code Not Generated"
            open
            actions={[
              {
                theme: "Filled - Lime Green Gradient",
                children: "Try Again",
                onClick: hideModal,
              },
            ]}
            hasCloseButton
            onClose={hideModal}
          />,
        );
        break;
      case "EMAIL_ALREADY_EXISTS_CODE":
        showModal(
          <ConfirmationDialog
            title="Oops!"
            body="This email has already in use! Try another."
            open
            actions={[
              {
                children: "Contact Us",
                onClick: async () => {
                  goToContactPage();
                  hideModal();
                },
                theme: "Filled - Lime Green Gradient",
              },
              {
                children: "Got it",
                onClick: hideModal,
              },
            ]}
            hasCloseButton
            onClose={hideModal}
          />,
        );
        break;
      case "SSO_EMAIL_NOT_REGISTERED":
        showModal(
          <ConfirmationDialog
            title="Oops!"
            body="This email has not yet been registered with KnoPro. Please sign up and try again."
            open
            actions={[
              {
                children: "Sign Up",
                onClick: async () => {
                  goToSignUpPage();
                  hideModal();
                },
                theme: "Filled - Lime Green Gradient",
              },
            ]}
            hasCloseButton
            onClose={hideModal}
          />,
        );
        break;
      case "ALREADY_A_PARTICIPANT_IN_DIFFERENT_PROJECT_SAME_CHALLENGE":
        showModal(
          <ConfirmationDialog
            open
            onClose={hideModal}
            actions={[
              {
                children: `Yes, Join Team`,
                theme: "Filled - Lime Green Gradient",
                onClick: async () => {
                  error?.extraFns?.();
                },
              },
              {
                children: `Cancel`,
                onClick: hideModal,
              },
            ]}
            title=""
            body={<Typo medium>{error?.message || ""}</Typo>}
          />,
        );
        break;
      default:
        showModal(
          <ConfirmationDialog
            title="Hmm. Looks like something went wrong. Please try again or Contact Support by clicking below."
            open
            actions={[
              {
                children: "Contact Support",
                onClick: async () => {
                  goToContactPage();
                  hideModal();
                },
                theme: "Filled - Lime Green Gradient",
              },
              {
                children: "Close",
                onClick: hideModal,
              },
            ]}
            onClose={hideModal}
          />,
        );
    }
  };
  return { handleError };
};

export interface ISuccessHandler {
  handleSuccess: (msg?: string) => void;
}

export const useSuccessHandlers = (): ISuccessHandler => {
  const { showModal, hideModal } = useContext(ContentModalContext);

  const handleSuccess = (msg?: string) => {
    showModal(
      <ConfirmationDialog
        open
        onClose={hideModal}
        actions={[
          {
            children: "Continue",
            theme: "Filled - Lime Green Gradient",
            onClick: hideModal,
          },
        ]}
        title=""
        body={
          <Typo h3>
            🎉<b>Congrats!</b> <br />
            {msg}
          </Typo>
        }
      />,
    );
  };
  return { handleSuccess };
};

export default useErrorHandlers;
