import { useState } from "react";
import { get } from "lodash";
import { logger } from "../utils";

export type Status = "IDEAL" | "PROCESSING" | "ERROR" | "SUCCESS";

export interface TAsyncTask<T> {
  run: (arg: T) => Promise<any>;
  status: Status;
  message: string;
  reset: () => void;
}

function useAsyncTask<T>(task: (arg: T) => Promise<any>): TAsyncTask<T> {
  const [status, setStatus] = useState<Status>("IDEAL");
  const [message, setMessage] = useState("");
  const run = async (arg: T) => {
    setStatus("PROCESSING");
    let response;
    try {
      response = await task(arg);
      setStatus("SUCCESS");
      if (response && response.message) setMessage(response.message);
    } catch (error: any) {
      logger.error(error);
      const message =
        get(error, "response.data.error.message") || error.message;
      setMessage(message);
      setStatus("ERROR");
    }
    return response;
  };
  const reset = () => {
    setMessage("");
    setStatus("IDEAL");
  };

  return {
    run,
    status,
    message,
    reset,
  };
}

export default useAsyncTask;
