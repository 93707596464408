/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, ReactElement, ReactNode, useContext, useEffect } from "react";
import { useRouter } from "next/router";
import {
  IS_USER_AUTHENTICATED,
  ROUTES,
  STORAGE_PROJECT_ID,
  STORAGE_TOKEN_KEY,
} from "../../Constants/variables";

import AuthContext from "../../AppContext/AuthProvider";
import { IStaticPageProps } from "../../models/Contentful/types";
import { LayoutPage } from "../../types/page";
// import LoginError from 'pages/login/error';
// import ContentModalContext from 'AppContext/ContentModalProvider';
// import MemberOnlyModal from 'components/ContentModal/MemberOnlyModal';
import styles from "./loader.module.sass";
import useAsyncTask from "../../hooks/useAsyncTask";
import useErrorHandlers from "../../hooks/useErrorHandlers";
import utils from "../../utils";

interface AuthHandlerProps {
  children: ReactNode;
  isPublic: boolean;
  denyIfAuthenticated: boolean;
}

export const PageLoader: FC = () => {
  return (
    <div className={styles.loaderWrapper}>
      <div className="button is-loading p-0" />
    </div>
  );
};

const PageHandler = ({
  children,
  isPublic = true,
  denyIfAuthenticated = false,
}: AuthHandlerProps) => {
  const { appUser, currentUser } = useContext(AuthContext);
  const { handleError } = useErrorHandlers();
  // const { showModal } = useContext(ContentModalContext);

  const getUserHandler = useAsyncTask(currentUser);
  // const userLoginHandler = useAsyncTask(login);
  const router = useRouter();
  useEffect(() => {
    if (
      utils.getCookie("isUserAuthenticated") === IS_USER_AUTHENTICATED.YES &&
      denyIfAuthenticated
    ) {
      router.push(ROUTES.DASHBOARD);
    }
  }, [denyIfAuthenticated]);

  useEffect(() => {
    const { id, token, errorCode, message, metaData } = router.query;
    if (id && token && !appUser) {
      if (typeof id === "string") utils.setCookie(STORAGE_PROJECT_ID, id);
      if (typeof token === "string") utils.setCookie(STORAGE_TOKEN_KEY, token);
    } else if (id && !appUser) {
      if (typeof id === "string") utils.setCookie(STORAGE_PROJECT_ID, id);
    }
    if (errorCode) {
      handleError({
        code: errorCode as string,
        message: message as string,
        metaData: metaData as string,
      });
    }
  }, [router]);

  useEffect(() => {
    if (!appUser) getUserHandler.run(null);
    return () => {
      getUserHandler.reset();
    };
  }, []);
  useEffect(() => {
    // if (!isPublic && getUserHandler.status === "ERROR")
    if (
      !isPublic &&
      utils.getCookie("isUserAuthenticated") === IS_USER_AUTHENTICATED.NO
    ) {
      if (localStorage.getItem("sso") !== "true") {
        router.push(ROUTES.LOGIN);
      }
    }
  }, [getUserHandler.status]);

  if ((isPublic && getUserHandler.status !== "IDEAL") || appUser || isPublic)
    return children as ReactElement<any>;

  // if (appUser && !isMember) return <NonMember />;

  return <PageLoader />;
};

function WithPage(
  Child: LayoutPage<IStaticPageProps<any>>,
  isPublic?: boolean,
  denyIfAuthenticated?: boolean,
) {
  return (props: IStaticPageProps<any>) => {
    const getLayout = Child.getLayout ?? ((page) => page);
    const { page } = props;
    return (
      <PageHandler
        denyIfAuthenticated={
          denyIfAuthenticated === undefined ? false : denyIfAuthenticated
        }
        isPublic={isPublic === undefined ? !page?.isPrivate : isPublic}
      >
        {getLayout(<Child {...props} />, props)}
      </PageHandler>
    );
  };
}

export default WithPage;
