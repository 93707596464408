/* eslint-disable react/no-children-prop */
import React, { FC } from "react";

import Button, { IButtonProps } from "../Button";
import ModalWrapper from "../ModalWrapper";
import Typo from "../Typo";
import styles from "./confirmationDialog.module.sass";
import { useIsMobileViewport } from "../../hooks/useIsMobileViewport";

export interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  body?: string | JSX.Element;
  message?: string;
  // onConfirm: IButtonProps;
  // onCancel?: IButtonProps;
  actions?: IButtonProps[];
  onClose?: () => void;
  hasCloseButton?: boolean;
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = (props) => {
  const { open, title, body, actions, onClose, message, hasCloseButton } =
    props;
  const { isMobile } = useIsMobileViewport();
  return (
    <ModalWrapper
      isActive={open}
      onClose={onClose}
      hasCloseButton={hasCloseButton}
    >
      <div className={`px-4 py-6 ${styles.root}`}>
        <div className={`has-text-centered mx-auto ${styles.childRoot}`}>
          <Typo h2>{title}</Typo>
          {body && (
            <Typo
              normal
              classes={`is-block mx-auto ${isMobile ? "mt-4" : "mt-5"} ${
                styles.body
              }`}
            >
              {body}
            </Typo>
          )}
          {actions?.length && (
            <div
              className={`is-flex is-justify-content-center is-align-items-center ${
                isMobile ? "is-flex-direction-column mt-5" : ""
              } ${styles.actionContainer}`}
            >
              {actions.map((a, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Button {...a} key={i} />
              ))}
            </div>
          )}
          {message && (
            <Typo bold classes="mt-4">
              {message}
            </Typo>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ConfirmationDialog;
